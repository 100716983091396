
/* fira-sans-200 - latin */
@font-face {
	font-family: 'Fira Sans';
	font-style: normal;
	font-weight: 200;
	src: url('./fonts/fira-sans-v10-latin-200.eot'); /* IE9 Compat Modes */
	src: local('Fira Sans ExtraLight'), local('FiraSans-ExtraLight'),
		url('./fonts/fira-sans-v10-latin-200.eot?#iefix') format('embedded-opentype'),
		/* IE6-IE8 */ url('./fonts/fira-sans-v10-latin-200.woff2') format('woff2'),
		/* Super Modern Browsers */ url('./fonts/fira-sans-v10-latin-200.woff') format('woff'),
		/* Modern Browsers */ url('./fonts/fira-sans-v10-latin-200.ttf') format('truetype'),
		/* Safari, Android, iOS */ url('./fonts/fira-sans-v10-latin-200.svg#FiraSans') format('svg'); /* Legacy iOS */
}
/* fira-sans-200italic - latin */
@font-face {
	font-family: 'Fira Sans';
	font-style: italic;
	font-weight: 200;
	src: url('./fonts/fira-sans-v10-latin-200italic.eot'); /* IE9 Compat Modes */
	src: local('Fira Sans ExtraLight Italic'), local('FiraSans-ExtraLightItalic'),
		url('./fonts/fira-sans-v10-latin-200italic.eot?#iefix') format('embedded-opentype'),
		/* IE6-IE8 */ url('./fonts/fira-sans-v10-latin-200italic.woff2') format('woff2'),
		/* Super Modern Browsers */ url('./fonts/fira-sans-v10-latin-200italic.woff') format('woff'),
		/* Modern Browsers */ url('./fonts/fira-sans-v10-latin-200italic.ttf') format('truetype'),
		/* Safari, Android, iOS */ url('./fonts/fira-sans-v10-latin-200italic.svg#FiraSans') format('svg'); /* Legacy iOS */
}
/* fira-sans-regular - latin */
@font-face {
	font-family: 'Fira Sans';
	font-style: normal;
	font-weight: 400;
	src: url('./fonts/fira-sans-v10-latin-regular.eot'); /* IE9 Compat Modes */
	src: local('Fira Sans Regular'), local('FiraSans-Regular'),
		url('./fonts/fira-sans-v10-latin-regular.eot?#iefix') format('embedded-opentype'),
		/* IE6-IE8 */ url('./fonts/fira-sans-v10-latin-regular.woff2') format('woff2'),
		/* Super Modern Browsers */ url('./fonts/fira-sans-v10-latin-regular.woff') format('woff'),
		/* Modern Browsers */ url('./fonts/fira-sans-v10-latin-regular.ttf') format('truetype'),
		/* Safari, Android, iOS */ url('./fonts/fira-sans-v10-latin-regular.svg#FiraSans') format('svg'); /* Legacy iOS */
}
/* fira-sans-italic - latin */
@font-face {
	font-family: 'Fira Sans';
	font-style: italic;
	font-weight: 400;
	src: url('./fonts/fira-sans-v10-latin-italic.eot'); /* IE9 Compat Modes */
	src: local('Fira Sans Italic'), local('FiraSans-Italic'),
		url('./fonts/fira-sans-v10-latin-italic.eot?#iefix') format('embedded-opentype'),
		/* IE6-IE8 */ url('./fonts/fira-sans-v10-latin-italic.woff2') format('woff2'),
		/* Super Modern Browsers */ url('./fonts/fira-sans-v10-latin-italic.woff') format('woff'),
		/* Modern Browsers */ url('./fonts/fira-sans-v10-latin-italic.ttf') format('truetype'),
		/* Safari, Android, iOS */ url('./fonts/fira-sans-v10-latin-italic.svg#FiraSans') format('svg'); /* Legacy iOS */
}
/* fira-sans-600italic - latin */
@font-face {
	font-family: 'Fira Sans';
	font-style: italic;
	font-weight: 600;
	src: url('./fonts/fira-sans-v10-latin-600italic.eot'); /* IE9 Compat Modes */
	src: local('Fira Sans SemiBold Italic'), local('FiraSans-SemiBoldItalic'),
		url('./fonts/fira-sans-v10-latin-600italic.eot?#iefix') format('embedded-opentype'),
		/* IE6-IE8 */ url('./fonts/fira-sans-v10-latin-600italic.woff2') format('woff2'),
		/* Super Modern Browsers */ url('./fonts/fira-sans-v10-latin-600italic.woff') format('woff'),
		/* Modern Browsers */ url('./fonts/fira-sans-v10-latin-600italic.ttf') format('truetype'),
		/* Safari, Android, iOS */ url('./fonts/fira-sans-v10-latin-600italic.svg#FiraSans') format('svg'); /* Legacy iOS */
}
/* fira-sans-600 - latin */
@font-face {
	font-family: 'Fira Sans';
	font-style: normal;
	font-weight: 600;
	src: url('./fonts/fira-sans-v10-latin-600.eot'); /* IE9 Compat Modes */
	src: local('Fira Sans SemiBold'), local('FiraSans-SemiBold'),
		url('./fonts/fira-sans-v10-latin-600.eot?#iefix') format('embedded-opentype'),
		/* IE6-IE8 */ url('./fonts/fira-sans-v10-latin-600.woff2') format('woff2'),
		/* Super Modern Browsers */ url('./fonts/fira-sans-v10-latin-600.woff') format('woff'),
		/* Modern Browsers */ url('./fonts/fira-sans-v10-latin-600.ttf') format('truetype'),
		/* Safari, Android, iOS */ url('./fonts/fira-sans-v10-latin-600.svg#FiraSans') format('svg'); /* Legacy iOS */
}
/* fira-sans-800 - latin */
@font-face {
	font-family: 'Fira Sans';
	font-style: normal;
	font-weight: 800;
	src: url('./fonts/fira-sans-v10-latin-800.eot'); /* IE9 Compat Modes */
	src: local('Fira Sans ExtraBold'), local('FiraSans-ExtraBold'),
		url('./fonts/fira-sans-v10-latin-800.eot?#iefix') format('embedded-opentype'),
		/* IE6-IE8 */ url('./fonts/fira-sans-v10-latin-800.woff2') format('woff2'),
		/* Super Modern Browsers */ url('./fonts/fira-sans-v10-latin-800.woff') format('woff'),
		/* Modern Browsers */ url('./fonts/fira-sans-v10-latin-800.ttf') format('truetype'),
		/* Safari, Android, iOS */ url('./fonts/fira-sans-v10-latin-800.svg#FiraSans') format('svg'); /* Legacy iOS */
}
/* fira-sans-800italic - latin */
@font-face {
	font-family: 'Fira Sans';
	font-style: italic;
	font-weight: 800;
	src: url('./fonts/fira-sans-v10-latin-800italic.eot'); /* IE9 Compat Modes */
	src: local('Fira Sans ExtraBold Italic'), local('FiraSans-ExtraBoldItalic'),
		url('./fonts/fira-sans-v10-latin-800italic.eot?#iefix') format('embedded-opentype'),
		/* IE6-IE8 */ url('./fonts/fira-sans-v10-latin-800italic.woff2') format('woff2'),
		/* Super Modern Browsers */ url('./fonts/fira-sans-v10-latin-800italic.woff') format('woff'),
		/* Modern Browsers */ url('./fonts/fira-sans-v10-latin-800italic.ttf') format('truetype'),
		/* Safari, Android, iOS */ url('./fonts/fira-sans-v10-latin-800italic.svg#FiraSans') format('svg'); /* Legacy iOS */
}
