:root {
	--primary: #85144b;
	--secondary: #DAEFF2;
	--primary-dark: #5f2441;
	--badge: #000;
	--badge-background: #efefef;
	--badge-background-dark: #cdcdcd;
	--dark-grey: #666;
}

@import './normalize';
@import './default';
@import './loader';
@import './checkbox';
@import './select';
@import './tags';
