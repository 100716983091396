/* CheckBox */

.mt-ios,
.mt-ios-blue,
.mt-ios-red {
	display: inline-block;
	box-sizing: border-box;
}
.mt-ios-blue label,
.mt-ios-red label,
.mt-ios label {
	display: inline-block;
	position: relative;
	box-sizing: border-box;
	cursor: pointer;
	width: 5em;
	height: 3em;
	border-radius: 1.5em;
	font-size: 0.75rem;
	background: #f8f8f8;
	box-shadow: inset 0 0 0 0 #13bf11, 0 0 0 0.1875em #ddd;
	transition: 0.25s ease-in-out;
}
.mt-ios-blue label:before,
.mt-ios-red label:before,
.mt-ios label:before {
	content: '';
	display: block;
	position: absolute;
	width: 3em;
	height: 3em;
	border-radius: 1.5em;
	background: #fff;
	box-shadow: 0 0.375em 0.375em rgba(0, 0, 0, 0.2), 0 0 0 0.1875em #ddd;
	transition: 0.25s ease-in-out;
}
.mt-ios-blue input,
.mt-ios-red input,
.mt-ios input {
	position: absolute;
	box-sizing: border-box;
	height: 0;
	width: 0;
	font-size: inherit;
	margin: 0;
	border: none;
	z-index: 1;
	cursor: pointer;
	-moz-appearance: none;
	-webkit-appearance: none;
}
.mt-ios-blue input:focus,
.mt-ios-red input:focus,
.mt-ios input:focus {
	outline: none;
}
.mt-ios-blue input:checked + label,
.mt-ios-red input:checked + label,
.mt-ios input:checked + label {
	box-shadow: inset 0 0 0 1.5em #13bf11, 0 0 0 0.1875em #13bf11;
}
.mt-ios-blue input:checked + label:before,
.mt-ios-red input:checked + label:before,
.mt-ios input:checked + label:before {
	transform: translateX(2em);
	box-shadow: 0 0 0 0.1875em transparent, 0 0.375em 0.375em rgba(0, 0, 0, 0.3);
}
.mt-ios-blue input:disabled + label,
.mt-ios-red input:disabled + label,
.mt-ios input:disabled + label {
	background: #ddd !important;
	box-shadow: inset 0 0 0 0 #13bf11, 0 0 0 0.1875em #ccc !important;
	cursor: not-allowed !important;
}
.mt-ios-blue input:disabled + label:before,
.mt-ios-red input:disabled + label:before,
.mt-ios input:disabled + label:before {
	background: #eee !important;
	box-shadow: none !important;
}
.mt-ios-red label {
	box-shadow: inset 0 0 0 0 #f35f42, 0 0 0 0.1875em #ddd;
}
.mt-ios-red input:checked + label {
	box-shadow: inset 0 0 0 1.5em #f35f42, 0 0 0 0.1875em #f35f42;
}
.mt-ios-blue label {
	box-shadow: inset 0 0 0 0 #1fc1c8, 0 0 0 0.1875em #ddd;
}
.mt-ios-blue input:checked + label {
	box-shadow: inset 0 0 0 1.5em #1fc1c8, 0 0 0 0.1875em #1fc1c8;
}
