*,
*:after,
*:before {
	box-sizing: inherit;
}

html {
	box-sizing: border-box;
	font-size: 62.5%;
	margin: 0;
	padding: 0;
}

body,
* {
	color: #222222;
	font-family: 'Fira Sans', sans-serif;
	font-size: 1.8rem;
	font-weight: 400;
	line-height: 1.6;
	margin: 0;
	padding: 0;
}

body {
	background-color: #eee;
}

label {
	width: 100%;
	display: block;
	font-size: 1.4rem;
	color: #999;
}

.form-control {
	margin-bottom: 1rem;
}

.form-group {
	margin-top: 2rem;
}

.white {
	color: #fff;
}

.m0 {
	margin: 0 !important;
}

.mr {
	margin-right: 1rem;
}

.mr2 {
	margin-right: 2rem;
}

.ml {
	margin-left: 1rem;
}

.ml2 {
	margin-left: 2rem;
}

.mt {
	margin-top: 1rem;
}

.mb {
	margin-bottom: 1rem;
}

.mt2 {
	margin-top: 2rem;
}

.mt3 {
	margin-top: 3rem;
}

.mb2 {
	margin-bottom: 2rem;
}

.link {
	&:hover {
		text-decoration: none;
	}
}

.small {
	font-size: 1.25rem;
}

.text-grey {
	color: #999;
}

.text-align-right {
	text-align: right;
}

.alert {
	display: block;
	width: 100%;
	background-color: rgba(0, 0, 0, 0.1);
	color: #000;
	padding: 2rem;
	border-radius: 0.5rem;
	text-align: left;

	&.alert-danger {
		background-color: var(--secondary);
		color: #000;
	}
}

.position-relative {
	position: relative;
}

.btn {
	border: 0;
	padding: 1rem;
	font-size: 1.4rem;
	background-color: var(--primary);
	width: 100%;
	cursor: pointer;
	border-radius: 0.5rem;
	transition: all 0.25s ease-out;
	text-decoration: none;

	&.btnSmall {
		width: auto;
		padding: 1rem 5rem;
	}

	&.btn50 {
		width: 50%;
	}

	&.btnBgDark {
		background-color: var(--dark-grey);
	}
}

.btn:hover {
	background-color: var(--primary-dark);
}

[type='text'],
[type='email'],
[type='password'] {
	width: 100%;
	border: 1px solid var(--badge-background);
	background-color: var(--badge-background);
	padding: 1rem;
	border-radius: 0.5rem;
	transition: all 0.25s ease-out;
}

[type='text']:hover,
[type='text']:active,
[type='text']:focus,
[type='email']:hover,
[type='email']:active,
[type='email']:focus,
[type='password']:hover,
[type='password']:active,
[type='password']:focus {
	border: 1px solid var(--badge-background-dark);
	background-color: #fff;
}
